var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header flex justify-between fixed top-0 left-0 w-full items- z-10 bg-gray-50 border-b-2 py-3 px-4 text-gray-700"
  }, [_c('div', {
    staticClass: "logo"
  }), _c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "flex items-center gap-5 pr-4"
  }, [_c('div', {
    staticClass: "text-white border-r border-white h-10 flex flex-col justify-center pr-6 relative cursor-pointer"
  }, [_c('div', {
    staticClass: "menu-item"
  }, [_c('div', {
    staticClass: "flex justify-center items-center cursor-pointer"
  }, [_c('span', {
    staticClass: "inline-block relative"
  }, [_c('span', {
    staticClass: "bg-system-error text-white absolute -right-1 -top-1 text-smallest w-4 h-4 flex justify-center items-center rounded-full"
  }, [_vm._v(_vm._s(_vm.notificationCount))]), _c('Notification', {
    attrs: {
      "color": "blue"
    }
  })], 1)]), _c('div', {
    staticClass: "submenu absolute right-0 shadow-sm w-screen max-w-md z-10"
  }, [_c('NotificationMenu', {
    on: {
      "refetch": function refetch($event) {
        return _vm.refetchNotification();
      }
    }
  })], 1)])]), _c('div', {
    staticClass: "flex items-center gap-3"
  }, [_c('div', {
    staticClass: "p-1 h-10 w-10 bg-yellow rounded-full border border-yellow flex justify-center items-center"
  }, [_c('p', {
    class: "font-bold text-white ".concat(_vm.customer.name && _vm.customer.name !== '' && _vm.forInitial(_vm.customer.name).length > 1 ? '' : 'text-lg')
  }, [_vm._v(" " + _vm._s(_vm.customer.name && _vm.customer.name !== '' ? _vm.forInitial(_vm.customer.name) : 'A') + " ")])]), _c('div', {
    staticClass: "customer cursor-pointer text-left"
  }, [_c('p', {
    staticClass: "font-bold text-base mb-0"
  }, [_vm._v(_vm._s(_vm.customer.name))])]), _c('div', {
    staticClass: "logout ml-3"
  }, [_c('span', {
    staticClass: "cursor-pointer"
  }, [_c('Chevron', {
    attrs: {
      "direction": "down",
      "width": "12",
      "color": "blue"
    }
  })], 1), _c('div', {
    staticClass: "dropdown invisible opacity-0 ease-linear transition-all duration-300 pt-6 right-0 top-full transform absolute cursor-default z-10"
  }, [_c('div', {
    staticClass: "bg-gray-100 border-4 shadow-small py-4 px-3 rounded-lg max-w-max flex items-center flex-col gap-2 "
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_c('router-link', {
    staticClass: "whitespace-nowrap inline-block text-sm font-semibold hover:bg-gray-200 py-3 px-6 rounded-xl",
    attrs: {
      "to": "/change-password"
    }
  }, [_vm._v(" Change Password")])], 1), _c('div', {
    staticClass: "hover:bg-gray-200 py-3 px-6 rounded-xl w-full"
  }, [_c('span', {
    staticClass: "text-sm inline-block font-semibold cursor-pointer text-center",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("Logout")])])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }